import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Flex, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Database } from "../supabase-schema";
import { Session as SupabaseSession } from '@supabase/supabase-js'
import Screen from "../components/Screen";
import FirstNameForm from "../components/profile/FirstNameForm"
import MotivationForm from "../components/profile/MotivationForm"
import Reminders from "../components/profile/Reminders"

type Props = {
    session: SupabaseSession,
    user: Database['public']['Tables']['users']['Row']
}

const ProfileScreen: React.FC<Props> = ({ session, user }) => {
    const { param } = useParams()
    const param2TabIndex = (param: string | undefined) => {
        switch (param) {
            case 'name':
                return 0
            case 'motivation':
                return 1
            case 'reminders':
                return 2
            default:
                return 0
        }
    }
    const [tabIndex, setTabIndex] = useState(param2TabIndex(param))
    const navigate = useNavigate()

    useEffect(() => {
        setTabIndex(param2TabIndex(param))
    }, [param])

    function onFirstNameFormSuccess() {
        if (!user.motivation) { setTabIndex(1) }
        else (navigate('/course'))
    }

    function onMotivationFormSuccess() {
        if (!user.reminder_frequency) { setTabIndex(2) }
        else (navigate('/course'))
    }

    function onRemindersFormSuccess() {
        navigate('/course')
    }

    const screenContent = (
        <Flex width={"100%"} direction='column' maxWidth={600} gap={5}>
            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)} isLazy size='lg' variant='line'>
                <TabList display='flex' flexWrap='wrap' justifyContent='space-between'>
                    <Tab p={2} style={{ opacity: 1 }}>
                        <Button colorScheme={!!user.first_name ? 'accent' : 'primary'}>
                            Name
                        </Button>
                    </Tab>
                    <Tab p={2} isDisabled={!user.first_name} style={{ opacity: 1 }}>
                        <Button colorScheme={!!user.motivation ? 'accent' : !user.first_name ? 'locked' : 'primary'}>
                            Motivation
                        </Button>
                    </Tab>
                    <Tab p={2} isDisabled={!user.motivation} style={{ opacity: 1 }}>
                        <Button colorScheme={user.reminder_frequency ? 'accent' : !user.motivation ? 'locked' : 'primary'}>
                            Reminders
                        </Button>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <FirstNameForm user={user} onSuccess={onFirstNameFormSuccess} />
                    </TabPanel>
                    <TabPanel>
                        <MotivationForm user={user} onSuccess={onMotivationFormSuccess} />
                    </TabPanel>
                    <TabPanel>
                        <Reminders user={user} session={session} onSuccess={onRemindersFormSuccess} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex >
    )

    return <Screen screenContent={screenContent} />
};

export default ProfileScreen;
