import React from "react";
import { useLocation } from "react-router-dom";
import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import { Database } from '../supabase-schema';
import Menu from "./Menu";
import logo from '../assets/logo.png'

type Props = {
    user?: Database['public']['Tables']['users']['Row'],
};

const Header: React.FC<Props> = ({ user }) => {
    const location = useLocation()
    const loginText = `Thank you for interest in our app! 
    This app will give you the tools to help you cope better with daily life stressors 
    that may come from being diagnosed or living with Parkinson's disease`
    const profileText = `Let's build your profile! 
    You only need to complete these fields once. 
    You can come back and change anything later.`
    const body = (text: string): JSX.Element => <Flex grow={1} wrap='wrap'><Text>{text}</Text></Flex>
    const content = (): JSX.Element => {
        const pageName = location.pathname.split('/')[1]
        if (!user) { return body(loginText) }
        if (pageName) {
            if (pageName === 'profile') {
                return (
                    <Flex direction='column'>
                        {body(profileText)}
                    </Flex>
                )
            }
            if (pageName === 'course' && user.motivation) { return body(`This is my ${pageName} to ${user.motivation.slice(2)}`) }
            if (pageName === 'session' && user.motivation) { return body(`This is my ${pageName} to ${user.motivation.slice(2)}`) }
        }
        return <></>
    }

    return (
        <Flex flexDirection={"column"} ml={[2, 30]} mr={[2, 30]}>
            <Flex justifyContent={"space-between"} >
                <Image width={[200, 300]} src={logo} alt='The logo of PACT: a stylised tulip representing strength and kindness.' />
                {user && <Menu user={user} />}
            </Flex>
            <Heading size="lg" style={{ flex: 1, flexWrap: 'wrap' }}>
                Welcome to PACT{user?.first_name && `, ${user?.first_name}`}!
            </Heading>
            <Flex grow={1} wrap='wrap'>
                {content()}
            </Flex>
        </Flex>
    );
};

export default Header;
