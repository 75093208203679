import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { AuthResponse } from '@supabase/supabase-js'
import {
  Alert, AlertIcon, Button, CloseButton, Flex,
  FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement
} from "@chakra-ui/react";
import AuthOptions from "../components/AuthOptions";
import Screen from "../components/Screen";

const LoginScreen = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false)
  const [alert, setAlert] = useState("")
  const navigate = useNavigate()

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { error }: AuthResponse = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) { setAlert(error.message) }
    else { navigate('/') }

  };

  const screenContent = (
    <Flex direction="column" width={"100%"} maxWidth={600} gap={5}>
      <Heading mb={0} size="md">Log in with password</Heading>
      <form onSubmit={handleLogin}>
        <FormControl isRequired>
          <FormLabel>Your email</FormLabel>
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl mt={4} isRequired>
          <FormLabel>Your password</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            <InputRightElement width='4.5rem'>
              <Button size='md' mr={1} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        {(alert &&
          <Alert mt={3} status='error' position="relative">
            <AlertIcon />
            {alert}
            <CloseButton
              position="absolute" right={0}
              onClick={() => { setAlert("") }}
            />
          </Alert>
        )}
        <Button mt={4} type="submit">
          Login
        </Button>
      </form>
      <AuthOptions page='password_login' />
    </Flex>
  )
  return <Screen screenContent={screenContent} />
};

export default LoginScreen;
