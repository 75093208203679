import { useState } from "react";
import {
    Alert, AlertIcon, Button, CloseButton, Flex, FormControl, FormLabel,
    Input, InputGroup, InputRightElement
} from "@chakra-ui/react";
import { supabase } from "../../supabaseClient";
import { Database } from "../../supabase-schema";

type Props = {
    user: Database['public']['Tables']['users']['Row'],
    onSuccess: CallableFunction
};

const ChangePasswordForm: React.FC<Props> = ({ user, onSuccess }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const [alert, setAlert] = useState('');

    const handleChangePassword = async () => {
        if (password === confirmPassword) {
            const { error: updateError } = await supabase.auth.updateUser({
                password,
            });
            if (updateError) {
                setAlert(updateError.message);
            } else {
                setPassword('')
                setConfirmPassword('')
                setAlert('')
                onSuccess()
            }
        } else {
            setAlert("Passwords don't match");
        }
    };
    return (
        <Flex direction='column' gap="32px">
            {(alert &&
                <Alert status='error' position="relative">
                    <AlertIcon />
                    {alert}
                    <CloseButton
                        position="absolute" right={0}
                        onClick={() => { setAlert("") }}
                    />
                </Alert>
            )}
            <FormControl id="password" isRequired>
                <FormLabel>New password</FormLabel>
                <InputGroup>
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement width='4.5rem'>
                        <Button size='md' mr={1} onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <FormControl id="confirmPassword" mt={4} isRequired>
                <FormLabel>Confirm your password</FormLabel>
                <InputGroup>
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter password"
                        value={confirmPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                    />
                    <InputRightElement width='4.5rem'>
                        <Button size='md' mr={1} onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? 'Hide' : 'Show'}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            <Flex justifyContent="right" wrap='wrap'>
                <Button onClick={handleChangePassword}>
                    Change
                </Button>
            </Flex>
        </Flex>
    )
}

export default ChangePasswordForm;