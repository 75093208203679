import { Database } from "../supabase-schema";
import { Flex, Heading, Image, useColorMode } from "@chakra-ui/react";
import iconAware from '../assets/icon_aware.png'
import iconEngage from '../assets/icon_engage.png'
import iconOpen from '../assets/icon_open.png'

type Theme = Database['public']['Enums']['theme']
type Props = {
    theme: Theme,
    locked?: boolean
}

const ThemeIcon: React.FC<Props> = ({ theme, locked = false }) => {
    switch (theme) {
        case 'open':
            return (
                <Flex gap={2} alignItems='center' >
                    <ThemeImage
                        image={iconOpen}
                        altText='The theme open represented by an open lock.'
                        description='This content helps you being open: accepting and not fighting or avoiding your thoughts and experiences'
                        locked={locked} />
                    <Heading size="sm" mb={0}>Being open</Heading>
                </Flex>
            )
        case 'aware':
            return (
                <Flex gap={2} alignItems='center' >
                    <ThemeImage
                        image={iconAware}
                        altText='The theme present represented by an eye at the intersection of four circles.'
                        description='This content helps you being aware: being more present to your experience and to your thoughts'
                        locked={locked} />
                    <Heading size="sm" mb={0}>Being present</Heading>
                </Flex>
            )
        case 'engage':
            return (
                <Flex gap={2} alignItems='center' >
                    <ThemeImage
                        image={iconEngage}
                        altText='The theme engaged represented by a pointed compass.'
                        description='This content helps you doing what matters: choosing what is important to you'
                        locked={locked} />
                    <Heading size="sm" mb={0}>Doing what matters</Heading>
                </Flex >
            )
        case 'review':
            return null
    }
}

type ImageProps = {
    image: string,
    altText: string,
    description: string,
    locked: boolean
}

const ThemeImage: React.FC<ImageProps> = ({ image, altText, description, locked }) => {
    const { colorMode } = useColorMode()
    return (
        <Image
            src={image}
            boxSize='30px'
            alt={altText}
            className={(colorMode === 'dark') ? 'invertedImage' : undefined}
            style={locked ? { opacity: 0.4 } : { cursor: 'pointer' }} />
    )
}

export default ThemeIcon;
