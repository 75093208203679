import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Database } from "../supabase-schema";
import { Button, Card, CardBody, Flex, Heading, Text } from "@chakra-ui/react";
import { StarIcon } from '@chakra-ui/icons';
import Screen from "../components/Screen";
import { getOpenUserSession } from "../api/userSessions";
import ThemeIcon from '../components/ThemeIcon'

type User = Database['public']['Tables']['users']['Row']
type Content = Database['public']['Tables']['content']['Row']
type Session = Database['public']['Tables']['sessions']['Row']
type UserSession = Database['public']['Tables']['usersessions']['Row']
type Reflection = Database['public']['Views']['decrypted_reflections']['Row']
interface SessionWithContent extends Session { content: Content }

const UserSessionsScreen: React.FC<{ user: User }> = ({ user }) => {
    const { sessionId } = useParams();
    const [session, setSession] = useState<SessionWithContent>()
    const [userSessions, setUserSessions] = useState<UserSession[]>()
    const navigate = useNavigate()

    async function getSession() {
        let { data, error } = await supabase
            .from('sessions')
            .select('*, content!inner(*)')
            .eq('id', sessionId)
            .single()
        if (error) { console.log(error); }
        if (data) { setSession(data as SessionWithContent) }
    }

    async function getUserSessions() {
        let { data, error } = await supabase
            .from('usersessions')
            .select('*')
            .eq('session_id', sessionId)
            .order('completion_date')
        if (error) { console.log(error); }
        if (data) { setUserSessions(data) }
    }

    useEffect(() => {
        getSession()
        getUserSessions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    async function handleOpen() {
        if (!sessionId || !userSessions) { return null }
        const userSessionId = await getOpenUserSession(user.id, Number(sessionId), userSessions)
        if (userSessionId) { navigate(`${userSessionId}`) }
    }

    const UserSessionRow: React.FC<{ userSession: UserSession }> = ({ userSession }): JSX.Element | null => {
        const [reflection, setReflection] = useState<Reflection>()
        const [loading, setLoading] = useState<boolean>(true)
        const [audioElement, setAudioElement] = useState<string | undefined>(undefined);

        async function getReflections() {
            let { data, error } = await supabase
                .from('decrypted_reflections')
                .select('*')
                .eq('usersession_id', userSession.id)
                .single()
            if (error) { console.log(error); }
            if (data) {
                setReflection(data)
                if (!data.audio) { setLoading(false) }
                else { fetchAudio(data.audio) }
            } else {
                setLoading(false)
            }
        }

        const fetchAudio = async (audioFilePath: string) => {
            const { data, error } = await supabase.storage
                .from('recordings').download(audioFilePath)
            if (error && !error.message.includes('not found')) {
                console.error('Error fetching audio element from Supabase:', error);
                return;
            }
            if (data) {
                const audioUrl = URL.createObjectURL(data);
                setAudioElement(audioUrl)
                setLoading(false)
            }
        };

        useEffect(() => {
            getReflections()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        if (loading) { return null }
        if (!userSession.completion_date) { return null }
        const completionDate = new Date(userSession.completion_date)

        return (
            <Card>
                <CardBody>
                    <Flex grow={1} justifyContent='space-between' alignItems='center' mb={2}>
                        <Text as='i'>{`${completionDate.toLocaleDateString()} at ${completionDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`}</Text>
                        <Flex>{Array.from(Array(userSession.rating).keys()).map(star => <StarIcon key={star} color="yellow.500" />)}</Flex>
                    </Flex>
                    <Flex gap={2} direction={'column'}>
                        <Flex grow={1} whiteSpace='pre-line'>{reflection?.decrypted_text}</Flex>
                        {reflection?.audio && <audio controls src={audioElement} />}
                    </Flex>
                </CardBody>
            </Card>
        )
    }

    if (!sessionId || !session || !userSessions) { return null }

    const screenContent = (
        <Flex direction="column" gap={5} width={"100%"} maxWidth={600} position="relative">
            <Flex direction="column" width={"100%"} gap={4}>
                <Heading size="md" mb={0}>Session {session.position_in_course}: {session.content.title}</Heading>
                <ThemeIcon theme={session.content.theme} />
                <Flex direction='column' gap={5}>
                    {userSessions.length > 0 && <Text fontWeight='bold'>Previous times you completed this session</Text>}
                    {userSessions?.map((userSession: UserSession) =>
                        userSession.completion_date && <UserSessionRow key={userSession.id} userSession={userSession} />)}
                </Flex>
                <Flex justifyContent="center" wrap='wrap' gap={2}>
                    <Button onClick={() => { navigate(`/course`) }} variant='back'>
                        Back to course
                    </Button>
                    <Button onClick={handleOpen}>
                        Do it again
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
    return <Screen screenContent={screenContent} />
};

export default UserSessionsScreen;