import { Box, Link, Text } from "@chakra-ui/react";

const Disclaimer = () => {

    return (
        <Box p={4} rounded="md" shadow="md">
            <Text mb={4}>
                PACT is designed to promote psychological well-being and provide general wellness support.
                It is not a medical device and is not intended to diagnose, treat, cure,
                or prevent any mental health conditions or illnesses.
            </Text>
            <Text mb={4}>
                The content and features within this app are for informational and educational purposes only
                and should not be considered as a substitute for professional medical advice, diagnosis, or treatment.
                Always seek the advice of your physician or other qualified health provider with any questions
                you may have regarding a medical condition.
            </Text>
            <Text mb={4}>
                If you experience any distressing symptoms or mental health issues,
                we strongly encourage you to contact your General Practitioner (GP)
                or a qualified healthcare professional immediately.
            </Text>
        </Box>
    )
};

export default Disclaimer;
