import { useState } from "react";
import { Button, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { supabase } from "../../supabaseClient";
import { Database } from "../../supabase-schema";

type Props = {
    user: Database['public']['Tables']['users']['Row'],
    onSuccess: CallableFunction
}

const FirstNameForm: React.FC<Props> = ({ user, onSuccess }): JSX.Element => {
    const [firstName, setFirstName] = useState<string | null>(user.first_name);
    const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const { error } = await supabase
                .from('users')
                .update({
                    first_name: firstName,
                })
                .eq('id', user.id)
            if (error) throw error;
            else { onSuccess() }
        } catch (error: any) {
            console.log(error);
        }
    };
    return (
        <form onSubmit={handleSave} style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
            <FormControl isRequired>
                <FormLabel>Your first name</FormLabel>
                <Input
                    placeholder="Enter your first name" value={firstName ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                />
            </FormControl>
            <Flex justifyContent="right" wrap='wrap'>
                <Button type="submit">
                    Save
                </Button>
            </Flex>
        </form>
    )
}

export default FirstNameForm;