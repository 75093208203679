import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Database } from "../supabase-schema";
import { Button, Flex, Heading, Text, Textarea, useToast } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Screen from "../components/Screen";
import StarRating from "../components/StarRating";
import AudioRecorder from "../components/AudioRecorder";
import Alert from "../components/Alert";
import VideoPlayer from "../components/VideoPlayer";
import ThemeIcon from '../components/ThemeIcon'
import CustomToast from '../components/CustomToast'

type User = Database['public']['Tables']['users']['Row']
type Content = Database['public']['Tables']['content']['Row']
type Session = Database['public']['Tables']['sessions']['Row']
type UserSession = Database['public']['Tables']['usersessions']['Row']
type ReviewQuestion = Database['public']['Tables']['reviewquestions']['Row']
type ReviewAnswer = Database['public']['Tables']['reviewanswers']['Insert']
interface SessionWithContent extends Session { content: Content }

const REVIEW_ANSWERS = ['Never', 'Rarely', 'Sometimes', 'Often', 'Always']
const CONTENT_URL = process.env.REACT_APP_SUPABASE_CONTENT_URL!

const SessionScreen: React.FC<{ user: User }> = ({ user }) => {
    const { sessionId, userSessionId } = useParams();
    const [session, setSession] = useState<SessionWithContent>()
    const [userSession, setUserSession] = useState<UserSession>()
    const [reflectionText, setReflectionText] = useState<string | null>()
    const [recordingPath, setRecordingPath] = useState<string | null>()
    const [audioBlob, setAudioBlob] = useState<Blob | null>()
    const [starRating, setStarRating] = useState<number | null>()
    const [reviewQuestions, setReviewQuestions] = useState<ReviewQuestion[]>([])
    const [reviewAnswers, setReviewAnswers] = useState<ReviewAnswer[]>([])
    const [isAlertOpen, setAlertOpen] = useState(false);
    const navigate = useNavigate()
    const toast = useToast()

    async function getSession() {
        let { data, error } = await supabase
            .from('sessions')
            .select('*, content!inner(*)')
            .eq('id', sessionId)
            .single()
        if (error) { console.log(error) }
        if (data) {
            setSession(data as SessionWithContent)
            getReviewQuestions(data.content_id)
        }
    }

    async function getUserSession() {
        try {
            let { data, error } = await supabase
                .from('usersessions')
                .select('*')
                .eq('id', userSessionId)
                .single()
            if (error) { throw error }
            if (data) {
                setUserSession(data)
                setStarRating(data.rating)
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    async function getReviewQuestions(contentId: number) {
        try {
            let { data, error } = await supabase
                .from('reviewquestions')
                .select('*')
                .eq('content_id', contentId)
                .order('id')
            if (error) { throw error }
            if (data) {
                setReviewQuestions(data)
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    async function getReviewAnswers() {
        try {
            let { data, error } = await supabase
                .from('reviewanswers')
                .select('*')
                .eq('user_id', user.id)
                .eq('session_id', sessionId)
            if (error) { throw error }
            if (data) {
                setReviewAnswers(data)
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    useEffect(() => {
        getSession()
        getUserSession()
        getReviewAnswers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    if (!sessionId || !session || !userSession) { return null }

    const handleSave = () => {
        if (canSaveSession() === false) {
            toast({
                render: () => <CustomToast description={disabledSaveMessage()} />,
                duration: 5000
            })
        } else if (session.content.theme === 'review') {
            handleSaveReview()
        } else {
            handleSaveSession()
        }
    }

    const handleSaveSession = async () => {
        const { error: usErr } = await supabase
            .from('usersessions')
            .update({ rating: starRating, completion_date: (new Date()).toISOString(), has_reflection_text: !!reflectionText, has_reflection_audio: !!recordingPath })
            .eq('id', userSession.id)
        if (usErr) { console.log(usErr) }
        const { error: rErr } = await supabase
            .from('reflections')
            .insert({ usersession_id: userSession.id, user_id: user.id, text: reflectionText, audio: recordingPath })
        if (rErr) { console.log(rErr) }
        if (recordingPath && audioBlob) {
            const { error: blErr } = await supabase.storage.from('recordings').upload(recordingPath, audioBlob);
            if (blErr) { console.log(blErr) }
        }
        navigate(`/course/${session.module}`)
    };

    const handleSaveReview = async () => {
        const { error: answersError } = await supabase
            .from('reviewanswers')
            .upsert(reviewAnswers)
        const { error: sessionError } = await supabase
            .from('usersessions')
            .update({ completion_date: (new Date()).toISOString() })
            .eq('id', userSession.id)
        if (answersError) { console.log(answersError); }
        else if (sessionError) { console.log(answersError); }
        else { navigate(`/course/${session.module}`) }
    };

    const handleAnswerQuestion = (questionId: number, answerValue: number) => {
        const answerAlreadyGiven = reviewAnswers.find(obj => obj.review_question_id === questionId)
        if (answerAlreadyGiven) {
            const updatedReviewAnswers = reviewAnswers.map((reviewAnswer: ReviewAnswer) => {
                if (reviewAnswer.review_question_id === questionId) {
                    return { ...reviewAnswer, answer: answerValue };
                }
                return reviewAnswer;
            })
            setReviewAnswers(updatedReviewAnswers);
        } else {
            const updatedReviewAnswers = [...reviewAnswers, {
                user_id: user.id,
                session_id: Number(sessionId),
                usersession_id: userSession.id,
                review_question_id: questionId,
                answer: answerValue
            }]
            setReviewAnswers(updatedReviewAnswers);
        }
    };

    function canSaveSession(): boolean {
        if (session?.content.theme === 'review') {
            return !userSession?.completion_date && reviewAnswers.length === reviewQuestions.length
        } else {
            return !!starRating
        }
    }

    const disabledSaveMessage = (): string => {
        if (session?.content.theme === 'review') {
            if (userSession?.completion_date) {
                return 'You cannot answer your progress questions twice'
            } else {
                return 'Answer all the questions before saving the session'
            }
        } else {
            return 'You need to rate the session before saving it'
        }
    }

    if (session.content.theme === 'review' && reviewQuestions.length === 0) { return null }

    const screenContent = (
        <Flex direction="column" gap={5} width={"100%"} maxWidth={600} position="relative">
            <Flex direction="column" width={"100%"} gap={4}>
                <Heading size="md" mb={0}>Session {session.position_in_course}: {session.content.title}</Heading>
                <ThemeIcon theme={session.content.theme} />
                {session.content.video_url && <VideoPlayer videoURL={CONTENT_URL + 'videos/' + session.content.video_url} />}
                {session.content.body &&
                    <Flex direction='column' gap={5}>
                        <Text as='i'>This is a text session, there is no video.</Text>
                        <Text whiteSpace='pre-line' dangerouslySetInnerHTML={{ __html: session.content.body }} />
                    </Flex>
                }
                {!session.content.video_url && !session.content.body &&
                    <CustomToast description="Sorry! The content of this session is momentarily unavailable or under development. Try again later or skip this session." isInPage />
                }
                {session.content.theme === 'review' ?
                    reviewQuestions.map((question: ReviewQuestion) => {
                        return (
                            <Flex direction='column' key={question.id} gap={5} mb={15}>
                                <Text fontWeight="bold" >{question.question}</Text>
                                <Flex wrap='wrap' direction={['column', 'row']} gap={1} justifyContent='center'>
                                    {[1, 2, 3, 4, 5].map((answer) => (
                                        <Flex key={answer} direction='column' alignItems="center" ml={[0, 1]}>
                                            <Text fontSize="sm" height={5}>
                                                {REVIEW_ANSWERS[answer - 1]}
                                            </Text>
                                            <Button
                                                isDisabled={!!userSession.completion_date}
                                                onClick={() => handleAnswerQuestion(question.id, answer)}
                                                variant={reviewAnswers.find(obj => obj.review_question_id === question.id)?.answer === answer ? 'solid' : 'outline'}
                                                colorScheme={reviewAnswers.find(obj => obj.review_question_id === question.id)?.answer === answer ? 'primary' : 'gray'}>
                                                {answer}
                                            </Button>
                                        </Flex>
                                    ))}
                                </Flex>
                            </Flex>
                        )
                    })
                    :
                    <Flex direction='column' gap={2}>
                        <Heading size="md">Your reflection</Heading>
                        <Text whiteSpace='pre-line' mb="1.5rem">{session.content.reflection_prompt}</Text>
                        <Tabs isFitted variant='enclosed' size='lg'>
                            <TabList>
                                <Tab><Text as='b'>Write reflection</Text></Tab>
                                <Tab><Text as='b'>Record audio reflection</Text></Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Textarea
                                        height={200}
                                        onChange={(e) => { setReflectionText(e.target.value) }}
                                        placeholder='Write your reflection here'
                                        value={reflectionText ?? ''}
                                        fontSize='lg'
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <AudioRecorder
                                        filename={`${user.id}/${session.id}/recording-${userSessionId}.mp3`}
                                        setRecordingPath={setRecordingPath}
                                        setAudioBlob={setAudioBlob}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text as='b' fontSize='2xl'>Did you find this session helpful?</Text>
                            <StarRating rating={starRating} onChange={setStarRating} />
                        </Flex>
                    </Flex>
                }
                <Text as='i'>
                    We use {session.content.theme === 'review' ? 'answers to these questions ' : 'session ratings '}
                    to recommended personalised sessions for you.
                    Please {session.content.theme === 'review' ? 'answers the questions ' : 'rate the session '}
                    before saving.
                </Text>
                <Flex justifyContent="center" wrap='wrap' gap={2}>
                    {(reflectionText || audioBlob || starRating || (reviewAnswers.length && !userSession.completion_date)) ?
                        (<Button variant='back' onClick={() => setAlertOpen(true)}>
                            Cancel and go back
                        </Button>)
                        :
                        (<Button variant='back' onClick={() => navigate(`/course`)}>
                            Go back
                        </Button>)
                    }
                    <Button colorScheme={!canSaveSession() ? 'locked' : 'primary'} onClick={handleSave}>Save</Button>
                </Flex>
            </Flex>
            <Alert
                isOpen={isAlertOpen}
                onClose={() => { setAlertOpen(false) }}
                onConfirm={() => { navigate(`/course`) }}
            />
        </Flex >
    )
    return <Screen screenContent={screenContent} />
};

export default SessionScreen;
