import { useNavigate } from "react-router-dom";
import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import Screen from "../components/Screen";

const SignUpSuccessScreen = () => {
  const navigate = useNavigate();

  const screenContent = (
    <Flex direction="column" width={"100%"} maxWidth={600} gap={5}>
      <Heading mb={0} size="md">Sign up</Heading>
      <Text>✅ You have succesfully signed up! Before logging in, check your inbox or spam folder to confirm your email.</Text>
      <Button onClick={() => { navigate('/password_login') }}>Back to login</Button>
    </Flex >
  )
  return <Screen screenContent={screenContent} />
};

export default SignUpSuccessScreen;
