import { useRef } from "react";
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    CloseButton,
    Flex,
    Text
} from "@chakra-ui/react";

type AlertProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    header?: string;
    body?: string;
    showConfirm?: boolean;
};

const Alert: React.FC<AlertProps> = ({ isOpen, onClose, onConfirm,
    header = 'Go back',
    body = 'Are you sure you want to lose your progress?',
    showConfirm = true }) => {
    const cancelRef = useRef(null)

    return (
        <AlertDialog
            leastDestructiveRef={cancelRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        <Flex>
                            {header}
                            <CloseButton
                                position="absolute" right={2}
                                onClick={onClose}
                            />
                        </Flex>
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text>{body}</Text>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        {showConfirm && <Button onClick={onConfirm} variant='back'>
                            Confirm
                        </Button>}
                        <Button ml={3} onClick={onClose}>
                            Cancel
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default Alert;
