import { useState } from "react";
import { supabase } from "../supabaseClient";
import { AuthResponse } from '@supabase/supabase-js'
import {
  Alert, AlertIcon, Button, CloseButton, Flex,
  FormControl, FormLabel, Heading, Input
} from "@chakra-ui/react";
import AuthOptions from "../components/AuthOptions"
import Screen from "../components/Screen";

type AlertOptions = "info" | "warning" | "success" | "error" | "loading" | undefined

const LoginLink = () => {
  const [email, setEmail] = useState<string>("");
  const [alert, setAlert] = useState("")
  const [altertType, setAlertType] = useState<AlertOptions>("error")
  const [sendingLink, setSendingLink] = useState<boolean>(false)

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSendingLink(true)
    const { error }: AuthResponse = await supabase.auth.signInWithOtp({ email });
    if (error) {
      setAlertType("error")
      setAlert(error.message)
    } else {
      setAlertType("success")
      setAlert("Login link sent. Check your inbox or spam folder.")
    }
    setSendingLink(false)
  };

  const screenContent = (
    <Flex direction="column" width={"100%"} maxWidth={600} gap={5}>
      <Heading mb={0} size="md">Log in with link</Heading>
      <form onSubmit={handleLogin}>
        <FormControl isRequired>
          <FormLabel>Your email</FormLabel>
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
        </FormControl>
        {(alert &&
          <Alert mt={3} status={altertType} position="relative">
            <AlertIcon />
            {alert}
            <CloseButton
              position="absolute" right={0}
              onClick={() => { setAlert("") }}
            />
          </Alert>
        )}
        <Button mt={4} type="submit" isDisabled={sendingLink}>
          Send link to login
        </Button>
      </form>
      <AuthOptions page='link_login' />
    </Flex>
  )
  return <Screen screenContent={screenContent} />
};

export default LoginLink;
