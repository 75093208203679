import { StarIcon } from '@chakra-ui/icons';
import { Flex, IconButton } from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
    rating?: number | null;
    onChange: (rating: number) => void;
}

const StarRating: React.FC<Props> = ({ rating, onChange }) => {
    const [hoverRating, setHoverRating] = useState<number | undefined>(undefined);

    const handleMouseEnter = (rating: number) => {
        setHoverRating(rating);
    };

    const handleMouseLeave = () => {
        setHoverRating(undefined);
    };

    const handleClick = (rating: number) => {
        onChange(rating);
    };

    const stars = [1, 2, 3, 4, 5];

    return (
        <Flex mb='4px'>
            {stars.map((star) => {
                const isFilled = hoverRating !== undefined ? star <= hoverRating : rating ? star <= rating : false;
                return (
                    <Flex key={star} _hover={{ cursor: 'pointer' }} width={8}
                        onClick={() => handleClick(star)}
                        onMouseEnter={() => handleMouseEnter(star)}
                        onMouseLeave={() => handleMouseLeave()}
                        justifyContent='center'
                    >
                        <IconButton
                            aria-label={`${star}-star rating`}
                            icon={<StarIcon />}
                            variant="unstyled"
                            p={0} m={0}
                            color={isFilled ? 'yellow.500' : undefined}

                        />
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default StarRating;
