import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button, Flex, Heading, Link, Text,
    Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";
import { supabase } from "../supabaseClient";
import { Database } from "../supabase-schema";
import { Session as SupabaseSession } from '@supabase/supabase-js'
import Alert from "../components/Alert";
import Screen from "../components/Screen";
import ChangePasswordForm from "../components/profile/ChangePasswordForm"

type Props = {
    session: SupabaseSession,
    user: Database['public']['Tables']['users']['Row']
}

const SettingsScreen: React.FC<Props> = ({ session, user }) => {
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false);
    const [deleteAlertBody, setDeleteAlertBody] = useState<string>('Are you sure you want to delete all your data and restart your journey?')
    const [deleteAlertButtons, setDeleteAlertButtons] = useState<boolean>(true);
    const navigate = useNavigate()

    function onPasswordFormSuccess() {
        navigate('/course')
    }

    const deleteUserRelatedData = async () => {
        try {
            // delete recordings
            const { data: userReflections, error: reflectionsError } = await supabase
                .from('reflections')
                .select('audio')
                .eq('user_id', user.id)
                .not('audio', 'is', null)

            if (reflectionsError) {
                throw reflectionsError
            }

            userReflections.map(async (file) => {
                if (file.audio) {
                    await supabase.storage.from('recordings').remove([file.audio])
                }

            })

            // delete review answers
            const { error: reviewanswersError } = await supabase
                .from('reviewanswers')
                .delete()
                .eq('user_id', user.id);

            if (reviewanswersError) {
                throw reviewanswersError;
            }

            // delete usersessions
            const { error: usersessionsError } = await supabase
                .from('usersessions')
                .delete()
                .eq('user_id', user.id);

            if (usersessionsError) {
                throw usersessionsError;
            }

            // delete user sessions
            const { error: sessionsError } = await supabase
                .from('sessions')
                .delete()
                .eq('user_id', user.id);

            if (sessionsError) {
                throw sessionsError;
            }

            navigate('/course')

        } catch (error) {
            console.error(error);
            setDeleteAlertBody("Sorry, there was a problem deleting your data. To proceed manually with your request please email us at compsci-pact@glasgow.ac.uk")
            setDeleteAlertButtons(false);
        }
    };

    const screenContent = (
        <Flex width={"100%"} direction='column' maxWidth={600}>
            <Tabs isLazy size='lg' variant='line'>
                <TabList display='flex' flexWrap='wrap' justifyContent='space-between'>
                    <Tab p={2} style={{ opacity: 1 }}>
                        <Button colorScheme='primary'>
                            Password
                        </Button>
                    </Tab>
                    <Tab p={2}>
                        <Button colorScheme='primary'>
                            Delete Data
                        </Button>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Heading size="md">Change Password</Heading>
                        <ChangePasswordForm user={user} onSuccess={onPasswordFormSuccess} />
                    </TabPanel>
                    <TabPanel>
                        <Heading mt={5} size="md">Delete Course Data & Reset</Heading>
                        <Text mb={5}>
                            By clicking the button below you can delete all your sessions and restart your journey from scratch
                        </Text>
                        <Button colorScheme='alert' onClick={() => { setDeleteAlertOpen(true) }}>
                            Delete data & reset
                        </Button>
                        <Text mt={5}>
                            If you wish to delete your user account entirely, please send an email to
                            <Link ml={1} href={`mailto:compsci-pact@glasgow.ac.uk`} color='primary.500' isExternal>
                                compsci-pact@glasgow.ac.uk
                            </Link>
                        </Text>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <Alert
                header='Delete data & reset'
                body={deleteAlertBody}
                isOpen={isDeleteAlertOpen}
                onClose={() => { setDeleteAlertOpen(false) }}
                onConfirm={() => { deleteUserRelatedData() }}
                showConfirm={deleteAlertButtons}
            />
        </Flex>
    )

    return <Screen screenContent={screenContent} />
};

export default SettingsScreen;
