import React from "react";
import { Flex } from "@chakra-ui/react";

type Props = {
    screenContent: JSX.Element | null,
};

const Screen: React.FC<Props> = ({ screenContent }) => {
    return (
        <Flex flexDirection={"column"} grow={1} pt={5} pb={50} ml={[2, 30]} mr={[2, 30]} >
            <Flex direction="column" grow={1} alignItems="center" justifyContent="flex-start" mt={[2, 10]} p={1}>
                {screenContent}
            </Flex>
        </Flex>
    );
};

export default Screen;
