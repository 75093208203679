import { useState, useEffect } from 'react';
import { supabase } from "../supabaseClient";
import { Database } from '../supabase-schema';

export default function useUser(userID: string) {
    const [user, setUser] = useState<Database['public']['Tables']['users']['Row']>()

    async function getUser() {
        try {
            const { data, error } = await supabase
                .from('users').select('*').eq('id', userID).single();
            if (error) { throw error }
            if (data) { setUser(data) }
        } catch (error: any) {
            console.log(error);
        }
    }


    const trackUserChanges = () => {
        try {
            supabase.channel('custom-filter-channel')
                .on('postgres_changes',
                    { event: '*', schema: 'public', table: 'users', filter: `id=eq.${userID}` },
                    (payload) => { setUser(payload.new as Database['public']['Tables']['users']['Row']) })
                .subscribe()
        } catch (error: any) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUser();
        trackUserChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userID]);

    return user;
}
