import { useRef } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { HiPlayPause } from "react-icons/hi2";

interface VideoPlayerProps {
    videoURL: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoURL }) => {
    const videoElement = useRef<HTMLVideoElement>(null);

    function videoToggle() {
        if (videoElement.current?.paused) {
            videoElement.current?.play()
        } else {
            videoElement.current?.pause()
        }
    }

    return (
        <Flex direction='column' gap={5} alignItems='center'>
            <Flex width='100%' border="1px" borderRadius="sm" boxShadow="md" borderColor="#212121">
                <video preload='auto' controls crossOrigin="anonymous" ref={videoElement} width='100%' playsInline>
                    <source src={videoURL} type='video/mp4' />
                    <track
                        src={videoURL.replace('videos', 'subs').replace('mp4', 'vtt')}
                        kind="subtitles"
                        srcLang="en"
                        label="English"
                        default
                    />
                </video>
            </Flex>
            <Button leftIcon={<HiPlayPause />} width={220} onClick={videoToggle}>Play / Pause</Button>
        </Flex>
    );
};

export default VideoPlayer;
