import { useState } from "react";
import { supabase } from "../supabaseClient";
import { AuthError } from '@supabase/supabase-js'
import {
  Alert, AlertIcon, Button, CloseButton,
  Flex, FormControl, FormLabel, Heading, Input
} from "@chakra-ui/react";
import Screen from "../components/Screen";
import AuthOptions from "../components/AuthOptions";

const ResetPasswordScreen = () => {
  const [email, setEmail] = useState<string>("");
  const [resetEmailSent, setResetEmailSent] = useState<boolean>(false)
  const [sendingLink, setSendingLink] = useState<boolean>(false)

  const handleForgotPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSendingLink(true)
    const { error }: { data: {}; error: null; } | { data: null; error: AuthError; } =
      await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/settings`,
      });
    if (error) {
      console.log(error);
    } else {
      setResetEmailSent(true)
    }
    setSendingLink(false)
  };

  const screenContent = (
    <Flex direction="column" width={"100%"} maxWidth={600} gap={5}>
      <Heading mb={0} size="md">Reset password</Heading>
      <form onSubmit={handleForgotPassword} >
        <FormControl isRequired>
          <FormLabel>Your email</FormLabel>
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
        </FormControl>
        {(resetEmailSent &&
          <Alert mt={3} status='success' position="relative">
            <AlertIcon />
            Reset email sent. Check your inbox or spam folder.
            <CloseButton
              position="absolute" right={0}
              onClick={() => { setResetEmailSent(false) }}
            />
          </Alert>
        )}
        <Button mt={4} type="submit" isDisabled={sendingLink}>
          Reset password
        </Button>
      </form>
      <AuthOptions page='reset_password' />
    </Flex>
  )

  return <Screen screenContent={screenContent} />
};

export default ResetPasswordScreen;
