import { supabase } from "../supabaseClient";
import { Database } from "../supabase-schema";

type UserSession = Database['public']['Tables']['usersessions']['Row']

export async function getOpenUserSession(userId: string, sessionId: number, userSessions: UserSession[] | undefined)
    : Promise<number | undefined> {
    const nonCompletedSessions = userSessions?.filter(usersession => !usersession.completion_date)
    if (nonCompletedSessions && nonCompletedSessions.length > 0) {
        return nonCompletedSessions[0].id
    } else {
        const { data, error } = await supabase
            .from('usersessions').insert({
                user_id: userId,
                session_id: sessionId,
                start_date: (new Date()).toISOString()
            })
            .select()
            .single()
        if (error) { throw console.log(error) }
        if (data) { return data.id }
    }
}