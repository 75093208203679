import React from "react";
import {
    Box,
    Button,
    Drawer,
    Flex,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    useColorMode,
} from "@chakra-ui/react";
import { Database } from '../supabase-schema';
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

type Props = { user?: Database['public']['Tables']['users']['Row'] };

const Menu: React.FC<Props> = ({ user }) => {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleClick = (link: string) => {
        onClose()
        navigate(link)
    }

    const menuButton = (label: string, link: string) => {
        return (
            <Button onClick={() => { handleClick(link) }} >
                {label}
            </Button>
        )
    }

    const ColorModeSwitcher: React.FC = () => {
        const { colorMode, toggleColorMode } = useColorMode()

        function handleClick() {
            onClose()
            toggleColorMode()
        }

        return (
            <Button
                onClick={handleClick}
                aria-label={`Switch to ${colorMode === 'dark' ? 'day' : 'night'} mode`}>
                Switch to {colorMode === 'dark' ? 'day' : 'night'} mode
            </Button >
        )
    }

    const handleLogOut = () => {
        supabase.auth.signOut()
        navigate('/login')
    }

    const LogoutButton = () => {
        return (
            <Button variant='back' onClick={handleLogOut}>
                Log out
            </Button>
        )
    }

    return (
        <Box>
            <Button onClick={onOpen}>
                Menu
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Menu</DrawerHeader>
                    <DrawerBody>
                        <Flex flexDirection={"column"} gap='2'>
                            {menuButton('Profile', '/profile/settings')}
                            {menuButton('Reminders', '/profile/reminders')}
                            {menuButton('Course', '/course')}
                            {menuButton('Help', '/help')}
                            {menuButton('Settings', '/settings')}
                            <ColorModeSwitcher />
                            <LogoutButton />
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default Menu;
