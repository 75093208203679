import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { AuthResponse } from '@supabase/supabase-js'
import {
  Alert, AlertIcon, Button, Checkbox, CloseButton, Collapse, Flex,
  FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement
} from "@chakra-ui/react";
import AuthOptions from "../components/AuthOptions"
import Disclaimer from "../components/Disclaimer";
import PrivacyNotice from "../components/PrivacyNotice";
import Screen from "../components/Screen";

const SignUpScreen = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [alert, setAlert] = useState("");
  const [isNoticeOpen, setNoticeOpen] = useState<boolean>(true);
  const [isAccepted, setIsAccepted] = useState<boolean>(false);

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setAlert('Passwords do not match')
    } else {
      const { data, error }: AuthResponse = await supabase.auth.signUp({
        email,
        password,
      });
      if (error) {
        setAlert(error.message)
      }
      else if (data.user?.identities?.length === 0) {
        setAlert("Email is already in use")
      }
      else {
        navigate('/signup_success')
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsAccepted(!isAccepted)
    setNoticeOpen(false)
  }

  const screenContent = (
    <Flex direction="column" width={"100%"} maxWidth={600} gap={5}>
      <Heading mb={0} size="md">Sign up</Heading>
      <form onSubmit={handleSignUp}>
        <FormControl isRequired>
          <FormLabel>Your email</FormLabel>
          <Input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="password" mt={4} isRequired>
          <FormLabel>Your password</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
            <InputRightElement width='4.5rem'>
              <Button size='md' mr={1} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="confirmPassword" mt={4} isRequired>
          <FormLabel>Confirm your password</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter password"
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
            />
            <InputRightElement width='4.5rem'>
              <Button size='md' mr={1} onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Privacy notice</FormLabel>
          <Collapse in={isNoticeOpen}>
            <PrivacyNotice />
          </Collapse>
          <Button mt={2} onClick={() => { setNoticeOpen(!isNoticeOpen) }} >
            {isNoticeOpen ? "Hide" : "Show"} privacy notice
          </Button>
          <Checkbox size="lg" isChecked={isAccepted} onChange={handleCheckboxChange} mt={4}>
            I consent to my data to be processed according to the privacy notice
          </Checkbox>
        </FormControl>
        {(alert &&
          <Alert mt={3} status="error" position="relative">
            <AlertIcon />
            {alert}
            <CloseButton
              position="absolute" right={0}
              onClick={() => { setAlert("") }}
            />
          </Alert>
        )}
        <Button mt={4} type="submit" isDisabled={!isAccepted} colorScheme='accent'>
          Sign up
        </Button>
      </form>
      <AuthOptions page='signup' />
      <Heading mb={0} size="md">Disclaimer</Heading>
      <Disclaimer />
    </Flex >
  )
  return <Screen screenContent={screenContent} />
};

export default SignUpScreen;
