import { useNavigate } from "react-router-dom";
import { Button, Heading, Flex, Link, Text } from "@chakra-ui/react";
import Disclaimer from "../components/Disclaimer";
import PrivacyNotice from "../components/PrivacyNotice";
import Screen from "../components/Screen";

const HelpScreen = () => {
    const navigate = useNavigate()

    const screenContent = (
        <Flex width={"100%"} direction='column' maxWidth={600} gap={5}>
            <Heading mb={0} size="md">Help resources</Heading>
            <Heading mb={0} size="sm">How does the course work?</Heading>
            <Text>
                Sessions in modules 1 and 2 cover core skills in Acceptance and Commitment Therapy.
                From module 3 onwards, you receive session recommendations on the basis of your
                ratings and progress reviews, or you can pick what you like in from the content library.
                Also, if you particularly enjoy a session, you can go and re-do it any time.
            </Text>
            <Heading mb={0} size="sm">More information</Heading>
            <Text>
                You can find more information about the app at
                <Link ml={1} href={`https://pact-app.com`} color='primary.500' isExternal>
                    https://pact-app.com
                </Link>
            </Text>
            <Flex gap={5} justifyContent='center'>
                <Button>
                    <Link ml={1} href={`https://bpb-eu-w2.wpmucdn.com/blogs.city.ac.uk/dist/2/2882/files/2023/10/User-manual.pdf`} isExternal>
                        User Manual
                    </Link>
                </Button>
                <Button>
                    <Link ml={1} href={`https://bpb-eu-w2.wpmucdn.com/blogs.city.ac.uk/dist/2/2882/files/2023/10/FAQs.pdf`} isExternal>
                        FAQ
                    </Link>
                </Button>
            </Flex>
            <Text>
                If you have any problem in using the app and you could not find an answer in the above manual,
                contact us at:
                <Link ml={1} href={`mailto:compsci-pact@glasgow.ac.uk`} color='primary.500' isExternal>
                    compsci-pact@glasgow.ac.uk
                </Link>
            </Text>
            <Heading mb={0} size="md">Privacy notice</Heading>
            <PrivacyNotice />
            <Heading mb={0} size="md">Deleting course data</Heading>
            <Text>
                Additionally, if you wish to automatically delete all your course data,
                you can easily do so from the Settings page
            </Text>
            <Flex justifyContent='center'><Button onClick={() => { navigate('/settings') }}>Go to Settings</Button></Flex>
            <Heading mb={0} size="md">Disclaimer</Heading>
            <Disclaimer />
        </Flex >
    )

    return <Screen screenContent={screenContent} />
};

export default HelpScreen;
