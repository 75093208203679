import { useState } from "react";
import { Button, Flex, FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import { supabase } from "../../supabaseClient";
import { Database } from "../../supabase-schema";
import logEvent from "../../api/logging";

type Props = {
    user: Database['public']['Tables']['users']['Row'],
    onSuccess: CallableFunction
};

type Motivation = Database['public']['Enums']['motivation']

const MOTIVATIONS: Motivation[] = [
    "To help me cope with my Parkinson’s symptoms",
    "To improve my confidence",
    "To help me handle difficult thoughts and feelings",
    "To help me cope with stress and anxiety",
    "To be more positive about my situation",
    "To help me better manage work",
    "To better manage relationships with friends and family",
    "To find balance and purpose in life",
    "To help me come to terms with my diagnosis",
    "To help me relax and sleep better",
    "To encourage me to do things to look after my wellbeing"
]

const MotivationForm: React.FC<Props> = ({ user, onSuccess }) => {
    const [motivation, setMotivation] = useState<Motivation>(user.motivation ?? MOTIVATIONS[0]);

    const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const previous_value = user.motivation?.valueOf()
        try {
            const { error } = await supabase
                .from('users')
                .update({ motivation })
                .eq('id', user.id)
            if (error) throw error;
            else {
                logEvent({
                    user_id: user.id, screen: 'motivation', button: 'save',
                    previous_value, current_value: motivation
                })
                onSuccess()
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <form onSubmit={handleSave} style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
            <FormControl isRequired>
                <FormLabel>What is your main motivation for using the app?</FormLabel>
                <Select value={motivation} onChange={(e) => setMotivation(e.target.value as Motivation)}>
                    {MOTIVATIONS.map((motivation: Motivation) => (
                        <option key={motivation} value={motivation}>
                            {motivation}
                        </option>
                    ))}
                </Select>
            </FormControl>
            <Text>
                Your motivation is used for the purpose of regularly reminding you
                why using the PACT app is important to you. Selecting one motivation
                over another does not exclude you from any content in the app.
            </Text>
            <Flex justifyContent="right" wrap='wrap'>
                <Button type="submit">
                    Save
                </Button>
            </Flex>
        </form>
    )
};

export default MotivationForm;
