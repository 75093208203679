import React, { useState, useRef, useEffect } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { FaMicrophone, FaStop } from 'react-icons/fa';
import Alert from './Alert';

interface AudioRecorderProps {
    filename: string;
    setRecordingPath: CallableFunction;
    setAudioBlob: CallableFunction
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ filename, setRecordingPath, setAudioBlob }) => {
    const [recording, setRecording] = useState(false);
    const [audio, setAudio] = useState<string | undefined>(undefined);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);

    const startRecording = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(mediaStream);

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(chunksRef.current, { type: 'audio/mp3' })
                setAudioBlob(audioBlob)
                setRecordingPath(`${filename}`)
                setAudio(URL.createObjectURL(audioBlob));
                chunksRef.current = [];
            };
            mediaRecorderRef.current = mediaRecorder;
            mediaRecorder.start();
            setRecording(true);
        } catch (error) {
            console.error('Error starting audio recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && recording) {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    const handleAlertConfirm = () => {
        setAlertOpen(false)
        startRecording()
    }

    return (
        <Flex direction="column" height={200} alignItems='center' justifyContent='center' gap={5}>
            <Button
                leftIcon={recording ? <FaStop /> : <FaMicrophone />}
                width={300}
                onClick={recording ? stopRecording : audio ? () => { setAlertOpen(true) } : startRecording}>
                {recording ? 'Stop recording' : audio ? 'Re-record' : 'Start recording'}
            </Button>
            {recording && (
                <Flex justifyContent='center' alignItems='center' mb={1}>
                    <Text as='i'>Recording in progress</Text>
                    <ThreeDots />
                </Flex>
            )}
            {!recording && audio && <audio controls src={audio} style={{ width: 300 }} />}
            <Alert
                isOpen={isAlertOpen}
                onClose={() => { setAlertOpen(false) }}
                onConfirm={handleAlertConfirm}
                header='Record audio reflection'
                body='An audio reflection already exist, are you sure you want to record a new one? The existing one will be lost.'
            />
        </Flex>
    );
};

const ThreeDots = () => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => (prevDots === '...' ? '' : `${prevDots}.`));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return <Flex minWidth={5}>{dots}</Flex>;
};

export default AudioRecorder;
