import { Link as RouterLink } from "react-router-dom";
import { Flex, Text, Link } from "@chakra-ui/react";
import { isIOSApp } from "../utils"

const AuthOptions = ({ page }: { page: string }) => {
    return (
        <Flex flexDirection="column" mt={4} gap={1}>
            {(page !== 'link_login' && !isIOSApp) && <Text>
                Or
                <Link ml={1} as={RouterLink} to='/link_login' color='primary.500'>
                    login with link
                </Link>
            </Text>}
            {page !== 'password_login' && <Text>
                Or
                <Link ml={1} as={RouterLink} to='/password_login' color='primary.500'>
                    login with password
                </Link>
            </Text>}
            {page !== 'signup' && <Text>
                Do not have an account?
                <Link ml={1} as={RouterLink} to='/signup' color='primary.500'>
                    Signup here
                </Link>
            </Text>}
            {page === 'password_login' && <Text>
                Forgotten you password?
                <Link ml={1} as={RouterLink} to='/reset_password' color='primary.500'>
                    Reset it
                </Link>
            </Text>}
            <Text>
                Problems logging in?
                <Link ml={1} href={`mailto:compsci-pact@glasgow.ac.uk`} color='primary.500' isExternal>
                    Contact us
                </Link>
            </Text>
        </Flex>
    )
}

export default AuthOptions