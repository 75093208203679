import { supabase } from "../supabaseClient";
import { Database } from '../supabase-schema';

type NewLogEvent = Database['public']['Tables']['loggingevents']['Insert']

export default async function logEvent(logEventObject: NewLogEvent) {
    const { error } = await supabase
        .from('loggingevents')
        .insert(logEventObject)
    if (error) { console.log(error) }
}