import { useState, useEffect } from 'react'
import { extendTheme, Flex, ChakraProvider, defineStyle } from "@chakra-ui/react"
import { BrowserRouter, Navigate, Routes, Route, useNavigate } from "react-router-dom";
import { Session as SupabaseSession } from '@supabase/supabase-js'
import { supabase } from "./supabaseClient"
import { Analytics } from '@vercel/analytics/react';
import { Database } from './supabase-schema';
import useUser from './api/users';
import logEvent from './api/logging';
import Header from './components/Header';
import CourseScreen from './screens/CourseScreen';
import HelpScreen from './screens/HelpScreen';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from "./screens/LoginScreen"
import LoginLinkScreen from "./screens/LoginLinkScreen"
import ProfileScreen from './screens/ProfileScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import SessionScreen from './screens/SessionScreen';
import SettingsScreen from './screens/SettingsScreen';
import SignUpScreen from "./screens/SignUpScreen"
import SignUpSuccessScreen from "./screens/SignUpSuccessScreen"
import UserSessionsScreen from './screens/UserSessionsScreen';

const solidButton = (props: any) => {
  const { colorScheme: c } = props;
  return {
    color: (c === 'primary') ? "#FFFFFF" : (c === 'locked') ? "#919191" : "#212121",
    borderColor: "#239BA4",
    cursor: (c === 'locked') ? 'not-allowed' : 'pointer'
  }
};

const backButton = (props: any) => {
  return {
    backgroundColor: 'primary.50',
    color: "#212121"
  }
};

const brandHeading = defineStyle({
  fontFamily: '"Tahoma", "Segoe UI", "Helvetica", "Arial"',
  marginBottom: "1.5rem"
})

export const theme = extendTheme({
  colors: {
    primary: {
      main: "#239BA4",
      50: "#A3BCBE",
      100: "#8CB2B4",
      200: "#75ABAF",
      300: "#5CA7AD",
      400: "#46A3AA",
      500: "#239BA4",
      600: "#2B8288",
      700: "#2F6D72",
      800: "#305D61",
      900: "#305053"
    },
    accent: {
      main: "#FAB15B",
      50: "#FCFAF8",
      100: "#F0E4D5",
      200: "#ECD1B1",
      300: "#EEC089",
      400: "#FAB15B",
      500: "#E6A04D",
      600: "#D09044",
      700: "#B88140",
      800: "#9B7345",
      900: "#846746"
    },
    alert: {
      main: "#FF6347",
      50: "#FFF5F3",
      100: "#FED8D3",
      200: "#FEB1A7",
      300: "#FF8C7A",
      400: "#FF725A",
      500: "#FF6347",
      600: "#FF5A45",
      700: "#FF4F45",
      800: "#FF4645",
      900: "#FF3C45"
    },
    locked: {
      main: "#F6F5FF",
      200: "#FBFAFF",
      300: "#F3F2FD",
      400: "#F0EFFB",
      500: "#EDECF9",
      600: "#EBEAF6",
      700: "#E9E8F3",
      800: '#E7E6F1'
    }
  },
  components: {
    Alert: {
      variants: {
        info: {
          container: {
            bg: '#ffffff'
          }
        }
      }
    },
    Button: {
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '24px',
        },
      },
      defaultProps: {
        size: 'xl',
        colorScheme: 'primary'
      },
      variants: {
        solid: solidButton,
        back: backButton
      }
    },
    Heading: {
      defaultProps: {
        variant: 'brand'
      },
      variants: {
        brand: brandHeading
      }
    },
    FormLabel: {
      sizes: {
        xl: {
          fontSize: 'lg',
        },
      },
      defaultProps: {
        size: 'xl'
      }
    },
    Input: {
      defaultProps: {
        size: 'lg'
      }
    },
    Select: {
      defaultProps: {
        size: 'lg',
      }
    },
    Switch: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'primary'
      }
    },
    Text: {
      variants: {
        locked: { opacity: 0.4 },
      },
      sizes: {
        xl: {
          fontSize: 'lg',
        },
      },
      defaultProps: {
        size: 'xl'
      }
    }
  },
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true,
  }
})

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [session, setSession] = useState<SupabaseSession | null>(null)

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      setSession(session)
      setIsLoading(false)
    })

    supabase.auth.onAuthStateChange(async (_event, session) => {
      setSession(session)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) { return null } else {
    return (
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Flex height="100vh" p={4}>
            {session ? <LoggedInRoutes session={session} /> : <LoggedOutRoutes />}
          </Flex>
        </BrowserRouter>
        <Analytics />
      </ChakraProvider >
    )
  }
}

function LoggedOutRoutes() {
  return (
    <Flex flexDirection={"column"} grow={1}>
      <Header />
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='link_login' element={<LoginLinkScreen />} />
        <Route path='password_login' element={<LoginScreen />} />
        <Route path='signup' element={<SignUpScreen />} />
        <Route path='signup_success' element={<SignUpSuccessScreen />} />
        <Route path='reset_password' element={<ResetPasswordScreen />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Flex>
  )
}

function LoggedInRoutes({ session }: { session: SupabaseSession }) {
  const user = useUser(session.user.id)
  useEffect(() => {
    if (user?.id) {
      logEvent({ user_id: user?.id, screen: 'app', previous_value: 'away', current_value: 'open' })
    }
  }, [user?.id])
  if (!user) { return null }
  return (
    <Flex flexDirection={"column"} grow={1}>
      <Header user={user} />
      <Routes>
        <Route path='/' element={<LandingPage user={user} />} />
        <Route path='profile/:param' element={<ProfileScreen user={user} session={session} />} />
        <Route path='settings' element={<SettingsScreen user={user} session={session} />} />
        <Route path='course' element={<CourseScreen user={user} />} />
        <Route path='help' element={<HelpScreen />} />
        <Route path='session/:sessionId' element={<UserSessionsScreen user={user} />} />
        <Route path='session/:sessionId/:userSessionId' element={<SessionScreen user={user} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Flex>
  )
}

function LandingPage({ user }: { user: Database['public']['Tables']['users']['Row'] }) {
  const navigate = useNavigate()
  useEffect(() => {
    if (!user.first_name) { navigate('profile/name') }
    else { navigate('course') }
  })
  return <></>
}

export default App
