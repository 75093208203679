export const isIOSApp = ('standalone' in window.navigator) && (window.navigator['standalone'])

export function isIOSOrIPadOS164Plus(): boolean {
    const userAgent = navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);

    if (isIOS) {
        const versionMatch = userAgent.match(/OS (\d+)_(\d+)/);

        if (versionMatch) {
            const majorVersion = parseInt(versionMatch[1], 10);
            const minorVersion = parseInt(versionMatch[2], 10);

            // Check for iOS or iPadOS version 16.4 or higher
            if (majorVersion > 16 || (majorVersion === 16 && minorVersion >= 4)) { return true }
        }
    }

    return false;
}