import React from 'react';
import { useToast, Flex, Text, IconButton, useColorModeValue, } from '@chakra-ui/react';
import { CloseIcon, InfoIcon } from '@chakra-ui/icons';

interface CustomToastProps {
    description: string;
    isInPage?: boolean;
}

const CustomToast: React.FC<CustomToastProps> = ({ description, isInPage = false }) => {
    const toast = useToast();
    const bgColor = useColorModeValue('primary.50', 'primary.700');
    const borderColor = useColorModeValue('primary.200', 'primary.900');
    const descriptionColor = useColorModeValue('#212121', 'white');

    return (
        <Flex
            p={3}
            bg={bgColor}
            border="1px"
            borderColor={borderColor}
            borderRadius="md"
            boxShadow="md"
            alignItems='center'
            gap={4}
            justifyContent={isInPage ? 'flex-start' : 'space-between'}
        >
            <InfoIcon boxSize={6} color={descriptionColor} />
            <Text color={descriptionColor}>{description}</Text>
            {!isInPage && <IconButton
                aria-label="Close"
                icon={<CloseIcon color={descriptionColor} />}
                size="lg"
                variant="unstyled"
                onClick={() => toast.closeAll()}
            />}
        </Flex>
    );
};

export default CustomToast;
