import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Database } from "../supabase-schema";
import { Button, Flex, Text } from "@chakra-ui/react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react'
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { getOpenUserSession } from "../api/userSessions";
import logEvent from "../api/logging";
import ThemeIcon from '../components/ThemeIcon'

type User = Database['public']['Tables']['users']['Row']
type Content = Database['public']['Tables']['content']['Row']
type Sessions = Database['public']['Tables']['sessions']['Row'][]
type RecommendationType = Database['public']['Tables']['recommendations']['Row']
interface ContentRecommendation extends RecommendationType {
    content: Content
}

type Props = { sessions: Sessions, user: User, module: number }

const Recommendation: React.FC<Props> = ({ sessions, user, module }) => {
    const [content, setContent] = useState<Content[]>([])
    const [recommendation, setRecommendation] = useState<ContentRecommendation>()
    const [pickingOwnSession, pickOwnSession] = useState<boolean>(false)
    const navigate = useNavigate()

    async function getContent() {
        let { data, error } = await supabase
            .from('content')
            .select('*')
            .not('video_url', 'is', null) // exclude videos that are not ready
            .order('title')
        if (error) { throw console.log(error); }
        if (data) { setContent(data) }
    }

    async function getRecommendation() {
        let { data, error } = await supabase
            .from('recommendations')
            .select('*, content!inner(*)')
            .eq('user_id', user.id)
            .order('created_at', { ascending: false })
            .limit(1)
            .single()
        if (error) { throw console.log(error); }
        if (data) { setRecommendation(data as ContentRecommendation) }
    }

    useEffect(() => {
        getContent()
        getRecommendation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function createSession(content: Content, button: string) {
        let { data, error } = await supabase
            .from('sessions')
            .insert({
                user_id: user.id,
                content_id: content.id,
                position_in_course: sessions.length + 1,
                module
            })
            .select()
            .single()
        if (error) { throw console.log(error); }
        else if (data) {
            logEvent({
                user_id: user.id, screen: 'recommendation', button, current_value: content.id.toString()
            })
            const userSessionId = await getOpenUserSession(user.id, data.id, [])
            if (userSessionId) { navigate(`/session/${data.id}/${userSessionId}`) }
        }
    }

    if (content.length === 0) { return null }

    const SessionsLibrary = () => {
        const completedContent = sessions.map(s => s.content_id)
        return (
            <Flex flexWrap='wrap' gap={2} justifyContent='center' mt={5}>
                {content
                    .filter(option => !completedContent.includes(option.id))
                    .map((option: Content) => {
                        return (
                            <Card width={275} size='sm' p={2}>
                                <CardHeader minHeight={20}><Text as='b'>{option.title}</Text></CardHeader>
                                <CardBody>
                                    <ThemeIcon theme={option.theme} />
                                    {option.description}
                                </CardBody>
                                <CardFooter justifyContent='center'>
                                    <Button onClick={async () => { createSession(option, 'pick-your-own') }}>Pick</Button>
                                </CardFooter>
                            </Card>
                        )
                    })}
            </Flex>
        )
    };

    if (!recommendation) { return null }
    return (
        <Flex width="100%">
            <Flex direction='column' mr='8px' alignItems='center'>
                <Flex borderWidth={1} width="1px" height='14px' borderColor='#239BA4' borderStyle='dashed' />
                <Flex width='30px' height='30px' borderRadius={100} backgroundColor='#239BA4' justifyContent='center' alignItems='center' >
                </Flex>
                <Flex borderWidth={1} flex="1" width="1px" borderColor='#239BA4' borderStyle='dashed' />
            </Flex>
            <Flex direction='column'>
                <Accordion index={0}>
                    <AccordionItem>
                        <AccordionButton minHeight={58}>
                            <Flex direction='column'>
                                <Text size="sm" textAlign='left' as='b' color='primary.main'>Session recommended for you</Text>
                                <Text textAlign='left'>{recommendation.content.title}</Text>
                            </Flex>
                        </AccordionButton>
                        <AccordionPanel>
                            <Text>{recommendation.content.description}</Text>
                            <Flex direction='column' alignItems='center' justifyContent='center' mt={4} gap={2} >
                                <Button width={225} onClick={async () => { createSession(recommendation.content, 'start-recommendation') }}>
                                    Start
                                </Button>
                                <Text>or</Text>
                                <Button width={225} onClick={() => { pickOwnSession(!pickingOwnSession) }} >Pick your own session</Button>
                            </Flex>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion >
                {pickingOwnSession && <SessionsLibrary />}
            </Flex>
        </Flex>
    )
}

export default Recommendation;