import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Database } from "../supabase-schema";
import { Button, Flex, Heading, Icon, Text, useToast } from "@chakra-ui/react";
import {
    Accordion, AccordionItem, AccordionButton,
    AccordionPanel, AccordionIcon
} from '@chakra-ui/react'
import { AiOutlineTrophy } from "react-icons/ai";
import Screen from "../components/Screen";
import Recommendation from "../components/Recommendation";
import CustomToast from '../components/CustomToast'
import { getOpenUserSession } from "../api/userSessions";


type User = Database['public']['Tables']['users']['Row']
type Content = Database['public']['Tables']['content']['Row']
type Session = Database['public']['Tables']['sessions']['Row']
type UserSession = Database['public']['Tables']['usersessions']['Row']
interface SessionWithContentAndUserSession extends Session {
    content: Content
    usersessions?: UserSession[]
}

const CourseScreen: React.FC<{ user: User }> = ({ user }) => {
    const [sessions, setSessions] = useState<SessionWithContentAndUserSession[]>([])
    const [moduleInView, setModuleInView] = useState<number>(1)
    const navigate = useNavigate()
    const toast = useToast()

    async function getSessions() {
        let { data, error } = await supabase
            .from('sessions')
            .select('*, content!inner(*), usersessions!left(*)')
            .or(`user_id.eq.${user.id}, user_id.is.null`)
            .eq('usersessions.user_id', user.id)
            .order('position_in_course')
        if (error) { throw console.log(error) }
        if (data) {
            const typedData = data as SessionWithContentAndUserSession[]
            setSessions(typedData)
            setModuleInView(findFistIncompleteModule(typedData))
        }
    }

    function findFistIncompleteModule(sessions: SessionWithContentAndUserSession[]): number {
        const firstIncompleteSession = sessions.find(session => isSessionCompleted(session) === false)
        if (firstIncompleteSession) { return firstIncompleteSession.module }
        else { return Math.min(4, Math.floor(sessions.length / 7) + 1) }
    }

    useEffect(() => {
        getSessions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function isSessionCompleted(session: SessionWithContentAndUserSession): boolean {
        if (!session) { return false }
        return !!session.usersessions?.filter(usersession => usersession.completion_date).length
    }

    function canOpenSession(session: Session): boolean {
        //if (user.is_admin) { return true }
        if (session.position_in_course === 1) { return true }
        else if (isSessionCompleted(sessions[session.position_in_course - 2])) { return true }
        return false
    }

    async function handleOpenSession(session: SessionWithContentAndUserSession) {
        if (canOpenSession(session) === false) {
            toast({
                render: () => <CustomToast description='You need to complete the previous sessions before you can open this one' />,
                duration: 5000
            })
        }
        else if (isSessionCompleted(session)) {
            if (session.content.theme === 'review') {
                navigate(`/session/${session.id}/${session.usersessions?.[0].id}`)
            } else {
                navigate(`/session/${session.id}`)
            }
        }
        else {
            const userSessionId = await getOpenUserSession(user.id, session.id, session.usersessions)
            if (userSessionId) { navigate(`/session/${session.id}/${userSessionId}`) }
        }
    }

    const Sessions: React.FC<{ sessions: SessionWithContentAndUserSession[] }> = ({ sessions }) => {
        const firstIncompleteSession = sessions.findIndex(session => isSessionCompleted(session) === false)
        return (
            <Accordion allowToggle defaultIndex={firstIncompleteSession} reduceMotion>
                {sessions.map((session: SessionWithContentAndUserSession, index) => {
                    const sColor = isSessionCompleted(session) ? '#FAB15B' : canOpenSession(session) ? '#239BA4' : '#F6F5FF'
                    return (
                        <Flex key={session.id}>
                            <Flex direction='column' mr='8px' alignItems='center'>
                                <Flex borderWidth={1} width="1px" height='14px' borderColor={sColor} borderStyle='dashed' />
                                <Flex width='30px' height='30px' borderRadius={100} backgroundColor={sColor} justifyContent='center' alignItems='center' >
                                    {isSessionCompleted(session) && <AiOutlineTrophy color='#212121' />}
                                </Flex>
                                <Flex borderWidth={1} flex="1" width="1px" borderColor={sColor} borderStyle='dashed' />
                            </Flex>
                            <AccordionItem flex="1">
                                <AccordionButton minHeight={58}>
                                    <Flex as="span" flex='1' textAlign='left' gap={2} alignItems='center'>
                                        <Text variant={!canOpenSession(session) ? 'locked' : undefined}>{session.content.title}</Text>
                                    </Flex>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    <Flex direction='column' gap={2} alignItems='center'>
                                        <Text variant={!canOpenSession(session) ? 'locked' : undefined}>{session.content.description}</Text>
                                        <Flex alignItems='center'>
                                            <Button
                                                colorScheme={isSessionCompleted(session) ? 'accent' : !canOpenSession(session) ? 'locked' : 'primary'}
                                                onClick={() => handleOpenSession(session)}
                                                width={225}>
                                                {isSessionCompleted(session) ? session.content.theme === 'review' ?
                                                    'Review answers' : 'Review or Redo' : 'Open'}
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem >
                        </Flex>
                    )
                })
                }
            </Accordion >
        )
    }

    if (sessions.length === 0) { return null }

    const moduleSessions = sessions.filter(session => session.module === moduleInView)

    function isModuleCompleted(moduleNumber: number): boolean {
        if (moduleNumber === 0) { return true }
        const moduleSessions = sessions.filter(session => session.module === moduleNumber)
        if (!moduleSessions || moduleSessions.length === 0 || moduleSessions.length < 7) { return false }
        return moduleSessions.every(isSessionCompleted)
    }

    function handleOpenModule(moduleNumber: number) {
        if (isModuleCompleted(moduleNumber - 1) === false) {
            toast({
                render: () => <CustomToast description={`You need to complete module ${moduleNumber - 1} before opening module ${moduleNumber}`} />,
                duration: 5000
            })
        } else {
            setModuleInView(moduleNumber)
        }
    }

    const screenContent = (
        <Flex direction="column" width={"100%"} maxWidth={600}>
            <Heading size="md">My journey</Heading>
            <Flex justifyContent={['center', 'space-between']} alignItems='center' wrap='wrap' gap={7}>
                {[1, 2, 3, 4].map((moduleNumber) => {
                    return (
                        <Flex alignItems="center" key={moduleNumber}>
                            <Flex>
                                <Button
                                    onClick={() => handleOpenModule(moduleNumber)}
                                    colorScheme={isModuleCompleted(moduleNumber) ? 'accent' : !isModuleCompleted(moduleNumber - 1) ? 'locked' : undefined}
                                    leftIcon={isModuleCompleted(moduleNumber) ? <AiOutlineTrophy /> : undefined}
                                    borderWidth={moduleInView === moduleNumber ? 2 : 0}
                                    borderColor='#212121'
                                    alignItems="center"
                                    justifyContent="center"
                                    width={120}>
                                    <Text> Module {moduleNumber}</Text>
                                </Button>
                            </Flex>
                        </Flex>
                    )
                })}
            </Flex>
            <Heading mt={5} size="md"> Module {moduleInView} - {(moduleInView < 3 ? "Core" : "Personalised")} Sessions</Heading>
            <Sessions sessions={moduleSessions} />
            {moduleSessions.length < 7 && ((isSessionCompleted(sessions[sessions.length - 1]) && isModuleCompleted(moduleInView - 1))) &&
                <Recommendation
                    sessions={sessions}
                    user={user}
                    module={moduleInView}
                />
            }
            <Text m={4}>
                For more detailed information about how the modules work
                consult our help resources.
            </Text>
            <Flex justifyContent='center'><Button onClick={() => { navigate('/help') }}>Go to Help</Button></Flex>
            {moduleInView === 4 && moduleSessions.length === 7 && isSessionCompleted(moduleSessions[6]) && (
                <Flex direction='column' gap={2} justifyContent='center' alignItems='center'>
                    <Heading mt={5} size="md">Congratulations!</Heading>
                    <Flex width='100px' height='100px' borderRadius={100} backgroundColor='#FAB15B' justifyContent='center' alignItems='center' >
                        <Icon boxSize={20} as={AiOutlineTrophy} />
                    </Flex>
                    <Text align='center'>You did it! You finished all available Modules, well done!</Text>
                    <Text align='center'>Remember that you can always re-do sessions that you have really enjoyed anytime by simply opening them again and selecting 'Do it again'.</Text>
                </Flex>)
            }
        </Flex>
    )
    return <Screen screenContent={screenContent} />
};

export default CourseScreen;
