import { useNavigate } from "react-router-dom";
import { Button, Flex, Link, Heading, Text } from "@chakra-ui/react";
import Disclaimer from "../components/Disclaimer";
import Screen from "../components/Screen";

const HomeScreen = () => {
  const navigate = useNavigate()

  const screenContent = (
    <Flex direction="column" width={"100%"} maxWidth={600} gap={5} justifyContent='space-around'>
      <Flex justifyContent='space-between' mb={10}>
        <Flex direction="column" rounded="md" shadow="md" p={5}>
          <Text align='center'>If you are a new</Text>
          <Button mt={4} onClick={() => { navigate('signup') }} colorScheme='accent'>
            Sign up
          </Button>
        </Flex>
        <Flex direction="column" rounded="md" shadow="md" p={5}>
          <Text align='center'>If you have an account</Text>
          <Button mt={4} onClick={() => { navigate('password_login') }} >
            Login
          </Button>
        </Flex>
      </Flex>
      <Heading mb={0} size="md">Contact</Heading>
      <Text>
        For any questions or issues regarding the PACT app, contact us at
        <Link ml={1} href={`mailto:compsci-pact@glasgow.ac.uk`} color='primary.500' isExternal>
          compsci-pact@glasgow.ac.uk
        </Link>
      </Text>
      <Heading mb={0} size="md">Disclaimer</Heading>
      <Disclaimer />
    </Flex>
  )
  return <Screen screenContent={screenContent} />
};

export default HomeScreen;
