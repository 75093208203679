import { Box, Link, Text } from "@chakra-ui/react";

const PrivacyNotice = () => {

    return (
        <Box p={4} rounded="md" shadow="md">
            <Text mb={4} fontWeight="bold">Your Personal Data</Text>
            <Text mb={4}>
                The University of Glasgow will be what is known as the ‘Data Controller’ of your
                personal data processed in relation to using the PACT App.
                This privacy notice explains how The University of Glasgow will process your personal data.
            </Text>
            <Text mb={4} fontWeight="bold">Why we need it</Text>
            <Text mb={4}>
                We are collecting your basic personal data such as first name and email address to provide
                you with access to the app, and your app usage data to personalise and monitor the app.
                We also collect limited special categories data relating to your mental health,
                such as your reflections on sessions and progress ratings, to personalise your
                further engagements with the app, as well as to allow you to access your past
                session reflections at a later stage.
            </Text>
            <Text mb={4} fontWeight="bold">Legal basis for processing your data</Text>
            <Text mb={4}>
                We must have a legal basis for processing all personal data. In this instance,
                the legal basis is Consent (GDPR (General Data Protection Regulation) Article 6(1), (a)).
            </Text>
            <Text mb={4} fontWeight="bold">What we do with it and who we share it with</Text>
            <Text mb={4}>
                All the personal data you submit is processed by staff at
                the University of Glasgow in the United Kingdom.
            </Text>
            <Text mb={4}>
                Personal data will also be shared with third-party data processors that helps us in
                running the PACT app by storing and retrieving encrypted data,
                as well as running computer programs that use such data.
                The third-party data processors that we use and share data with are Supabase and MailerSend,
                both of which are GDPR compliant.
            </Text>
            <Text mb={4} fontWeight="bold">How long do we keep it for</Text>
            <Text mb={4}>
                Your data will be securely stored on third-party platforms while the PACT app
                is maintained and running. If at any point the PACT app is terminated,
                we will notify all users of the upcoming deletion of their data at least
                one month before the data deletion.
            </Text>
            <Text mb={4} fontWeight="bold">What are your rights?</Text>
            <Text mb={4}>
                You can request access to the information we process about you at any time.
                If at any point you believe that the information we process relating to you is incorrect,
                you can request to see this information and may in some instances request to have it restricted,
                corrected, or erased. You can also request for your account and all the information
                we retain about you to be deleted at any time
            </Text>
            <Text mb={4}>
                If you wish to exercise any of these rights, please submit your request via the webform or contact
                Our Data Protection Officer can be contacted at
                <Link ml={1} href={`mailto:dp@gla.ac.uk`} color='primary.500' isExternal>
                    dp@gla.ac.uk
                </Link>
            </Text>
            <Text mb={4} fontWeight="bold">
                Please note that the ability to exercise these rights will vary and depend on the legal basis
                on which the processing is being carried out.
            </Text>
            <Text mb={4} fontWeight="bold">Complaints</Text>
            <Text mb={4}>
                If you wish to raise a complaint on how we have handled your personal data,
                you can contact the University Data Protection Officer who will investigate the matter.
                Our Data Protection Officer can be contacted at
                <Link ml={1} href={`mailto:dataprotectionofficer@glasgow.ac.uk`} color='primary.500' isExternal>
                    dataprotectionofficer@glasgow.ac.uk
                </Link>
            </Text>
            <Text mb={4}>
                If you are not satisfied with our response or believe we are not processing your personal data
                in accordance with the law, you can complain to the Information Commissioner’s Office (ICO)
                <Link ml={1} href={`https://ico.org.uk`} color='primary.500' isExternal>
                    https://ico.org.uk
                </Link>
            </Text>
        </Box>
    )
};

export default PrivacyNotice;
